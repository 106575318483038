<template>
  <Layout>
    <Header class="header">
      <Breadcrumb class="breadcrumb">
        <BreadcrumbItem to="/sys_manage/user">系统用户</BreadcrumbItem>
        <BreadcrumbItem>修改</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Content class="content">
      <Form ref="dataForm" :model="formData" :rules="rules" :label-width="100" >
        <FormItem label="用户名称：" prop="username">
          <Input v-model="formData.username" />
        </FormItem>
        <FormItem label="真实姓名：" prop="realName">
          <Input v-model="formData.realName" />
        </FormItem>
        <FormItem label="手机号码：" prop="phone">
          <Input type="number" v-model="formData.phone" />
        </FormItem>
        <FormItem label="Email地址：" prop="email">
          <Input type="email" v-model="formData.email" />
        </FormItem>
        <FormItem label="单位：" prop="officeId">
          <Select v-model="formData.officeId" style="width:100%">
            <Option v-for="item in officeList" :value="item.id" :key="item.id">
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button style="background: #00A0E9;" type="primary" @click="submit">保存</Button>
        </FormItem>  
      </Form>  
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    const validatePass = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入密码'))
        } else {
            if (this.formData.passwdCheck !== '') {
                // 对第二个密码框单独验证
                this.$refs.dataForm.validateField('passwdCheck')
            }
            callback()
        }
    };
    const validatePassCheck = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请再次输入密码'))
        } else if (value !== this.formData.password) {
            callback(new Error('两次输入的密码不一致!'))
        } else {
            callback()
        }
    };
    const validatePhone = (rule, value, callback) => {
        if (!value) {
          callback()
        } else if (!/^1[345678]\d{9}$/.test(value)) {
            callback('请填写正确的手机号')
        } else {
            callback()
        }
    }
    return {
      userId: null,
      formData: {
        username: '',
        realName: '',
        password: '',
        passwdCheck: '',
        phone: '',
        email: '',
        officeId: '',
      },
      rules: {
        username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        realName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        passwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }],
        phone: [{ validator: validatePhone, trigger: 'blur'}],
        email: [ { type: 'email', message: 'email格式有误', trigger: 'blur'}],
        officeId: [ { message: '请选择单位', trigger: 'change' }],
      },
      officeList: [],
    }
  },
  mounted() {
    this.userId = this.$route.params.id
    this.getData()
    this.initOfficeList()
  },
  methods: {
    initOfficeList() {
      request.get('/api/sys/office/operable_list')
      .then((data) => this.officeList = data)
    },
    getData() {
      request.get(`/api/sys/user/${this.userId}`)
      .then((data) => this.formData = data)
    },
    submit() {
      const params = this.formData
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          request.put(`/api/sys/user/${this.userId}`, params)
          .then(() => {
            this.$Message.success('修改成功!')
            this.$router.replace('/sys_manage/user')
          })
        }
      })
    },
  },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    justify-content: center;
  }
  form {
    width: 450px;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
